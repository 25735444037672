<template>
    <b-card no-body>
        <div class="m-2 p-1 rd-viva  bg-viva">
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    ลูกค้า
                    <div class="demo-vertical-spacing ml-2">
                        <b-input-group class="input-group-merge">
                           
                            <b-form-input placeholder="ค้นหาลูกค้า" v-model="searchQuery" />
                            <b-input-group-append is-text>
                                <feather-icon icon="SearchIcon" color="#58ADEB" />
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col
                        cols="12"
                        md="6"
                >
                <div class="d-flex align-items-center justify-content-end">
                    
                    <b-button 
                        @click="this.sendNotify"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="success">
                        แจ้งเตือนนัดหมายพรุ่งนี้
                    </b-button>        
                </div> 
                </b-col>
            </b-row>
        </div>
        <div class="m-2">
            
            <vue-good-table
            :columns="tableColumns"
            :rows="Data"
            ref="refListTable"
           
            :select-options="{
                enabled: false,
                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
              :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
           
            
            >
            <template
            slot="table-row"
            slot-scope="props"
        >
                <span
                v-if="props.column.field === 'first_name'"
                class="text-nowrap"
                
                >   
                    <span class="text-nowrap">{{ props.row.prefix }} {{ props.row.first_name }} {{ props.row.last_name }} </span>
               
                </span>
                <span
                v-else-if="props.column.field === 'access_token'"
                class="text-nowrap"
                
                >   
                <b-button size="sm" class="btn-icon rounded-circle bg-success border-0" v-if="props.row.access_token" ></b-button>
                <b-button size="sm" class="btn-icon rounded-circle bg-warning border-0" v-else ></b-button>
                </span>
                <span
                v-else-if="props.column.field === 'text'"
                class="text-nowrap"
                >   
                <b-button :id="`popover-1-${props.row.id}`" variant="flat-success" v-if="props.row.text">
                    ข้อความล่าสุด
                </b-button>
                <b-popover
                :target="`popover-1-${props.row.id}`"
                title="ข้อความล่าสุด"
                triggers="hover focus"
                variant="success"
                :content="props.row.text"
                ></b-popover>
                </span>
                
                <span
                v-else-if="props.column.field === 'qr_code'"
                class="text-nowrap"
                > 
                <b-button
                    size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-success"
                    class="btn-icon rounded-circle" 
                    v-b-modal="`modal-${props.row.id}`"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-qr-code" viewBox="0 0 16 16">
                        <path d="M2 2h2v2H2V2Z"/>
                        <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z"/>
                        <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z"/>
                        <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z"/>
                        <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z"/>
                    </svg>
                </b-button>
                <template>
                <b-modal size="set" centered :hide-footer="true" :hide-header="true" :id="`modal-${props.row.id}`">
                    <div class="m-auto">
                        <a :href="currentUrl+'/linenotify/detail?patient_id='+props.row.id">
                        <qr-code :text="currentUrl+'/linenotify/detail?patient_id='+props.row.id"></qr-code>
                        </a>
                    </div>
                </b-modal>
                </template>
                </span>
            </template>

            <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
             Items per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
           
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="Total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
             
              class="mt-1 mb-0"
            
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
            </vue-good-table>
            
        </div>

    </b-card>
    
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BSpinner,BPopover
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import useLineList from './useLineList'
import linenotifyStoreModule from '../linenotifyStoreModule'
import { ref,onUnmounted } from '@vue/composition-api'
import router from '@/router'
import VueQRCodeComponent from 'vue-qrcode-component'
export default {
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BFormGroup,BFormCheckbox,
        FeatherIcon,BInputGroup,BInputGroupPrepend,BInputGroupAppend,BSpinner,BModal,BPopover,
        VueGoodTable,
        'qr-code':VueQRCodeComponent,

    },
    directives:{
        Ripple,
        
    },
    setup() {
        const currentUrl = ref('http://localhost:3002');
        const PATIENTS_STORE_MODULE_NAME = 'app-linenotify';
        if (!store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.registerModule(PATIENTS_STORE_MODULE_NAME, linenotifyStoreModule)
        onUnmounted(() => {
            if (store.hasModule(PATIENTS_STORE_MODULE_NAME)) store.unregisterModule(PATIENTS_STORE_MODULE_NAME)
        });
        const {
            tableColumns,
            fetchData,
            deleteData,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            currentPage,
            refListTable,
            sendNotify
        } = useLineList();
        return {
            tableColumns,
            fetchData,
            deleteData,
            pageLength,
            dir,
            Data,
            Total,
            searchQuery,
            currentPage,
            refListTable,
            currentUrl,
            sendNotify
        }

        
    },
    methods:{
        handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
        },
        deleteconfirm(id,point_id,title){
         this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete patient: '+title, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
           this.deleteData({id:id,point_id:point_id})
          }
        })

        },
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
      #qrcode_img {
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
        margin-top: -9em;
        margin-left: -9em;
        border: 1px solid #666;
        background-color: #ffffff;
        position: fixed;
      }
      .modal .modal-set {
        max-width: 295px;
        width: 296px;
        margin: auto;
        }
</style>

