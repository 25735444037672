import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useLineList() {
  const searchQuery = ref('');
  const refListTable = ref(null);
    const currentPage = ref(1);
   const pageLength = ref(10);
   const Total = ref(0);
   const dir = ref(false);
   const toast = useToast()
    const tableColumns = [

      { field: 'access_token', label: 'Line', sortable: false },
      { field: 'qr_code', label: 'QR Code', sortable: false },
      { field: 'code_member', label: 'รหัสลูกค้า', sortable: false },

        { field: 'first_name', label: 'ชื่อ-สกุล', sortable: false },
        { field: 'phone', label: 'โทร', sortable: false },
        { field: 'email', label: 'อีเมล', sortable: false },

        { field: 'text', label: 'ข้อความล่าสุด', sortable: false },
        { field: 'notify_updated', label: 'แจ้งเตือนล่าสุด', sortable: false },


    ]
    const Data = ref([]);
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const refetchData = () => {
      refListTable.value.refresh()
  }
    watch([currentPage, pageLength, searchQuery], () => {
      fetchData();
    })
    const fetchData = () => {
        const offset = pageLength.value*(currentPage.value-1);
        Data.value  = [];
        store
          .dispatch('app-linenotify/fetchData', {
            limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            branch_id:branch_id,
            order_by:'patients.id DESC'
         
          })
          .then(response => {
          
           Data.value  = response.data.data
            console.log('value',Data.value);
            const total = response.data.total?response.data.total: Data.value.length;
           
            Total.value = total
          })
          .catch((error) => {
            console.log('error',error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Patients' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    const deleteData = (data) => {
      //fetchDeleteProperty
      
      var res = store.dispatch('app-linenotify/deleteData',data)
      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error Delete",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
    }
    
    fetchData();

    const sendNotify = () => {
      //fetchDeleteProperty
      
      var res = store.dispatch('app-linenotify/notifyDaily')
      res.then(response=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "notification Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        fetchData();
      }).catch(()=>{
        toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
  
      })
    }
    return {
        tableColumns,
        fetchData,
        deleteData,
        Total,
        Data,
        pageLength,
        dir,
        searchQuery,
        currentPage,
        refListTable,
        sendNotify
    }
}